<template>
    <div>
        <el-card class="box-card card-contanier">
            <div class="card-content"></div>
            <div class="card-content">
                <el-form :inline="true" @submit.native.prevent>
                    <div style="float: left; width: 94%">
                        <el-form-item label="分组名称：">
                            <el-input
                                    v-model="searchForm.mgName"
                                    placeholder="分组名称"
                                    size="medium"
                                    clearable
                                    @keyup.enter.native="search"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="分组类型：">
                            <el-select v-model="searchForm.mgType" placeholder="请选择">
                                <el-option
                                        v-for="item in options"
                                        :key="item.mgType"
                                        :label="item.label"
                                        :value="item.mgType"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="search" icon="el-icon-search" size="medium">
                                搜索
                            </el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="refresh" icon="el-icon-refresh" size="medium">
                                重置
                            </el-button>
                        </el-form-item>
                    </div>
                    <el-form-item>
                        <el-button
                                type="primary"
                                @click="addMerchantGroup"
                                v-if="hasAuth('sy:mercgroup:add')"
                                size="medium">
                            新增
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>

        <el-table
                ref="merchantTable"
                :data="tableData"
                v-loading="isLoading"
                tooltip-effect="dark"
                border
                style="width: 100%"
                stripe
                size="small"
                :row-style="{height:'10px'}"
                :cell-style="{padding:'1px 0'}"
        >
            <!--<el-table-column type="selection" width="55" align="center"></el-table-column>-->
            <el-table-column prop="mgId" label="分组id" align="center"></el-table-column>
            <el-table-column prop="mgName" label="分组名称" align="center"></el-table-column>
            <el-table-column label="分组类型" align="center">
                <template slot-scope="scope">
          <span>
            <el-tag v-if="scope.row.mgType === 0" size="small">商超</el-tag>
            <el-tag v-if="scope.row.mgType === 1" type="success" size="small">服务</el-tag>
            <el-tag v-if="scope.row.mgType === 2" type="info" size="small">餐饮</el-tag>
          </span>
                </template>
            </el-table-column>
            <el-table-column prop="meNum" label="商户数量" align="center"></el-table-column>
            <el-table-column prop="alName" label="所属联盟" align="center"></el-table-column>
            <el-table-column prop="mgCreate" label="分组时间" align="center">
                <template slot-scope="scope">
                    {{ scope.row.mgCreate.replace('00:00:00','') }}
                </template>
            </el-table-column>
            <el-table-column prop="mgUpdate" label="修改时间" align="center">
                <template slot-scope="scope">
                    {{ scope.row.mgUpdate.replace('00:00:00','') }}
                </template>
            </el-table-column>
            <el-table-column
                    prop="icon"
                    width="260px"
                    label="操作"
                    align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="edit(scope.row)" v-if="hasAuth('sy:mercgroup:edit')">编辑</el-button>
                    <el-divider direction="vertical" v-if="hasAuth('sy:mercgroup:edit')"></el-divider>
                    <el-button type="text" @click="deleteMerchantGroup(scope.row.mgId)" v-if="hasAuth('sy:mercgroup:delete')">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
            ></el-pagination>
        </div>

        <el-dialog :title="isAdd ? '新增' : '修改-' +titleName+ '商户分组'" :visible.sync="addDialogVisible" width="800px" :close-on-click-modal="false">
            <div v-loading="dialogLoading" class="merchant-form">
                <el-form
                        :model="ruleForm"
                        :rules="ruleFormRules"
                        ref="ruleForm"
                        label-width="100px"
                        class="demo-ruleForm"
                >
                    <el-form-item label="分组名称：" prop="mgName">
                        <el-input v-model="ruleForm.mgName" placeholder="请输入分组名称" maxlength="20" show-word-limit style="width: 400px;"></el-input>
                    </el-form-item>
                    <el-form-item label="分组类型：" prop="mgType">
                        <el-radio-group v-model="ruleForm.mgType">
                            <el-radio :label="0">商超</el-radio>
                            <el-radio :label="1">服务</el-radio>
                            <el-radio :label="2">餐饮</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="序号：" prop="mgOrder">
                        <el-input-number v-model="ruleForm.mgOrder" :min="1" label="请输入分组排序号"></el-input-number>
                    </el-form-item>
                    <el-form-item label="选择商户：" prop="meIdList">
                        <el-transfer
                                v-model="ruleForm.meIdList"
                                :data="merchantList"
                                :props="{ key: 'meId', label: 'meName' }"
                                :titles="['未分组商户', '已分组商户']"></el-transfer>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer" style="text-align:right">
                    <el-button type="primary" @click="onSubmit()">保存</el-button>
                    <el-button @click="() => { this.addDialogVisible = false; }">取消</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>


<script>
    import api from "../../api/merchantGroup";
    export default {
        name: "merchantGrouping",
        data() {
            return {
                isAdd: true,
                editId: '',
                addDialogVisible: false,
                isLoading: false,
                dialogLoading: false,
                total: 10,
                size: 10,
                current: 1,
                tableData: [],
                titleName:"",
                options: [
                    {
                        mgType: 0,
                        label: "商超",
                    },
                    {
                        mgType: 1,
                        label: "服务",
                    },
                    {
                        mgType: 2,
                        label: "餐饮",
                    },
                ],
                searchForm: {
                    mgName: '',
                    mgType: ''
                },
                ruleForm: {
                    mgName: '',
                    mgType: '',
                    mgOrder: '',
                    meIdList: [],
                    mgId: ''//修改时使用
                },
                merchantList: [],
                ruleFormRules: {
                    mgName: [
                        { required: true, message: "请输入分组名称", trigger: "blur" },
                    ],
                    mgType: [
                        { required: true, message: "请选择分组类型", trigger: "blur" },
                    ],
                    mgOrder: [
                        { required: false, message: "请输入序号", trigger: "blur" },
                    ],
                    meIdList: [
                        { required: true, message: "请至少选择一个商户", trigger: "blur" },
                    ],
                },
            };
        },
        created() {
            this.getTableList();
            this.getAllMerchantsInfo();
        },
        methods: {
            search() {
                this.current = 1;
                this.getTableList();
            },
            refresh() {
                this.searchForm = {
                    mgType: '',
                    mgName: '',
                };
                this.current = 1;
                this.getTableList();
            },
            getTableList() {
                this.isLoading = true;
                api.list({
                    params: {
                        mgName: this.searchForm.mgName,
                        mgType: this.searchForm.mgType,
                        current: this.current,
                        size: this.size
                    },
                }).then((res) => {
                    this.tableData = res.data.data.result.records;
                    this.size = res.data.data.result.size;
                    this.current = res.data.data.result.current;
                    this.total = res.data.data.result.total;
                    this.isLoading = false;
                });
            },
            getAllMerchantsInfo(mgId) {
                api.getAllMerchants({
                    params: {
                        mgId: mgId
                    }
                }).then((res) => {
                    this.merchantList = res.data.data.result;
                });
            },
            handleSizeChange(val) {
                this.size = val;
                this.getTableList();
            },
            handleCurrentChange(val) {
                this.current = val;
                this.getTableList();
            },
            addMerchantGroup() {
                this.resetForm('ruleForm');
                this.getAllMerchantsInfo();
                this.isAdd = true;
                this.addDialogVisible = true;
            },
            resetForm(formName) {
                if(this.$refs[formName] != null && this.$refs[formName] !== undefined) {
                    this.$refs[formName].resetFields();
                }
                this.ruleForm = {
                    mgName: '',
                    mgType: '',
                    mgOrder: '',
                    meIdList: [],
                    mgId: ''//修改时使用
                };
            },
            onSubmit() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        this.dialogLoading = true;
                        api.changeMerchantGroupInfo(this.isAdd ? "add" : "edit", this.ruleForm).then(res => {
                            if (res.data.code === 200) {
                                this.$notify({
                                    title: "操作成功",
                                    message: "",
                                    position: "bottom-right",
                                    type: "success",
                                });
                                this.dialogLoading = false;
                                this.addDialogVisible = false;
                                this.getTableList();
                            }
                        }).catch(e => {
                            this.dialogLoading = false;
                        });
                    }
                });
            },
            edit(row) {
                this.isAdd = false;
                this.ruleForm.mgId = row.mgId;
                this.isLoading = true;
                this.getAllMerchantsInfo(row.mgId);
                api.getInfoById(row.mgId).then(res => {
                    let merchantGroupInfo = res.data.data.result;
                    this.ruleForm.mgName = merchantGroupInfo.mgName;
                    this.ruleForm.mgType = merchantGroupInfo.mgType;
                    this.ruleForm.mgOrder = merchantGroupInfo.mgOrder;
                    this.ruleForm.meIdList = merchantGroupInfo.meIdList;
                    this.isLoading = false;
                    this.addDialogVisible = true;
                    this.titleName = merchantGroupInfo.mgName;
                });
            },
            deleteMerchantGroup(mgId) {
                this.$confirm('删除后，原该分组下的商户可重新选择分组，是否确认删除该商户分组？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api.deleteMerchantById(mgId).then(res => {
                        if(res.data.code === 200){
                            this.$notify({
                                title: "prefect",
                                message: "操作成功",
                                type: "success",
                                position: "bottom-right",
                            });
                        } else {
                            this.$notify({
                                title: "prefect",
                                message: "操作失败",
                                type: "error",
                                position: "bottom-right",
                            });
                        }
                        this.search();
                    });
                }).catch(() => {

                });
            },
        },
    };
</script>

<style scoped>
    .el-pagination {
        margin-top: 20px;
        text-align: right;
    }
</style>
