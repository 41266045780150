import { postRequest, getRequest, deleteRequest } from '../utils/request'

export default {
    list(params) {//列表
        const { params: para } = params
        return getRequest('/sy/merChan/list', para)
    },
    getInfoById(id) {//根据id查询详情
        return getRequest(`/sy/merChan/info/${id}`)
    },
    getAllMerchants(params) {
        const { params: para } = params
        return getRequest('/sy/merChan/getAllMerchants', para)
    },
    changeMerchantGroupInfo(status, params) {
        return postRequest(`/sy/merChan/${status}`, params)
    },
    deleteMerchantById(id) {
        return deleteRequest(`/sy/merChan/delete/${id}`)
    },
}
